import { Directive, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appShowBrowserSpeed]'
})
export class ShowBrowserSpeedDirective implements OnInit {
  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    const speed = performance.navigation.type === 1 ? 'Fast' : 'Slow';
    const text = this.renderer.createText(`Browser speed: ${speed}`);
    this.renderer.appendChild(document.body, text);
  }
}