import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, ViewChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const _c0 = ["videoBg"];
class Html5VideoComponent {
  constructor() {
    this.playerVars = {};
    this.playsinline = true;
    // tslint:disable: no-output-rename
    // tslint:disable: no-output-on-prefix
    this.load = new EventEmitter();
    this.ready = new EventEmitter();
    this.onPlay = new EventEmitter();
    this.onPause = new EventEmitter();
    this.playerDefaults = {
      autoplay: false,
      controls: true,
      muted: false,
      loop: false
    };
    this.isLoaded = false;
    this.stateClassName = 'is--unloaded';
  }
  log(e) {
    console.log(e);
  }
  async ngOnInit() {
    this.playerVars = Object.assign(this.playerDefaults, this.playerVars);
  }
  ngOnChanges(changes) {
    const videoEl = this.ref.nativeElement;
    // Set isLoaded true when video is ready to play
    this.isLoaded = videoEl.readyState === 4;
    videoEl.muted = this.playerVars.muted || false;
    if (!this.playerVars.autoplay) {
      !this.ref.nativeElement.paused ? this.ref.nativeElement.play() : this.ref.nativeElement.pause();
    }
  }
  play() {
    this.ref.nativeElement.play();
  }
  pause() {
    this.ref.nativeElement.pause();
  }
  togglePlay() {
    const videoEl = this.ref.nativeElement;
    if (videoEl.paused) {
      this.play();
    } else {
      this.pause();
    }
  }
  _volumeChange(event) {
    const videoEl = this.ref.nativeElement;
    this.playerVars = Object.assign(this.playerVars, {
      muted: videoEl.muted
    });
  }
  static {
    this.ɵfac = function Html5VideoComponent_Factory(t) {
      return new (t || Html5VideoComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: Html5VideoComponent,
      selectors: [["fstr-html5-video"]],
      viewQuery: function Html5VideoComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.ref = _t.first);
        }
      },
      inputs: {
        src: "src",
        playerVars: "playerVars",
        poster: "poster",
        playsinline: "playsinline"
      },
      outputs: {
        load: "load",
        ready: "ready",
        onPlay: "play",
        onPause: "pause"
      },
      exportAs: ["fstrHtml5Video"],
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 3,
      vars: 11,
      consts: [["playsinline", "playsinline", 1, "fstr-html5-video__container__video", 3, "loop", "muted", "autoplay", "poster", "controls", "src", "load", "play", "pause", "canplay", "volumechange"], ["videoBg", ""]],
      template: function Html5VideoComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div")(1, "video", 0, 1);
          i0.ɵɵlistener("load", function Html5VideoComponent_Template_video_load_1_listener($event) {
            return ctx.load.emit($event);
          })("play", function Html5VideoComponent_Template_video_play_1_listener($event) {
            return ctx.onPlay.emit($event);
          })("pause", function Html5VideoComponent_Template_video_pause_1_listener($event) {
            return ctx.onPause.emit($event);
          })("canplay", function Html5VideoComponent_Template_video_canplay_1_listener($event) {
            return ctx.ready.emit($event);
          })("volumechange", function Html5VideoComponent_Template_video_volumechange_1_listener($event) {
            return ctx._volumeChange($event);
          });
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("fstr-html5-video__container ", ctx.stateClassName, "");
          i0.ɵɵadvance();
          i0.ɵɵclassProp("is--loaded", ctx.isLoaded);
          i0.ɵɵproperty("loop", ctx.playerVars.loop)("muted", ctx.playerVars.muted)("autoplay", ctx.playerVars.autoplay)("poster", ctx.poster, i0.ɵɵsanitizeUrl)("controls", ctx.playerVars.controls)("src", ctx.src, i0.ɵɵsanitizeUrl);
        }
      },
      styles: [".fstr-html5-video__container[_ngcontent-%COMP%]{width:100%;height:0;overflow:hidden;padding-bottom:56.25%;position:relative;display:block}  .fstr-html5-video__container__video{width:100%;height:100%;position:absolute;inset:0}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Html5VideoComponent, [{
    type: Component,
    args: [{
      selector: 'fstr-html5-video',
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'fstrHtml5Video',
      template: "<div class=\"fstr-html5-video__container {{ stateClassName }}\">\n  <video\n    class=\"fstr-html5-video__container__video\"\n    [loop]=\"playerVars.loop\"\n    [muted]=\"playerVars.muted\"\n    [autoplay]=\"playerVars.autoplay\"\n    [poster]=\"poster\"\n    [controls]=\"playerVars.controls\"\n    [src]=\"src\"\n    playsinline=\"playsinline\"\n    (load)=\"load.emit($event)\"\n    (play)=\"onPlay.emit($event)\"\n    (pause)=\"onPause.emit($event)\"\n    (canplay)=\"ready.emit($event)\"\n    (volumechange)=\"_volumeChange($event)\"\n    [class.is--loaded]=\"isLoaded\"\n    #videoBg\n  ></video>\n</div>\n",
      styles: [".fstr-html5-video__container{width:100%;height:0;overflow:hidden;padding-bottom:56.25%;position:relative;display:block}::ng-deep .fstr-html5-video__container__video{width:100%;height:100%;position:absolute;inset:0}\n"]
    }]
  }], function () {
    return [];
  }, {
    src: [{
      type: Input
    }],
    playerVars: [{
      type: Input
    }],
    poster: [{
      type: Input
    }],
    playsinline: [{
      type: Input
    }],
    load: [{
      type: Output
    }],
    ready: [{
      type: Output
    }],
    onPlay: [{
      type: Output,
      args: ['play']
    }],
    onPause: [{
      type: Output,
      args: ['pause']
    }],
    ref: [{
      type: ViewChild,
      args: ['videoBg', {
        static: true
      }]
    }]
  });
})();
class Html5VideoPlayerModule {
  static {
    this.ɵfac = function Html5VideoPlayerModule_Factory(t) {
      return new (t || Html5VideoPlayerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: Html5VideoPlayerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Html5VideoPlayerModule, [{
    type: NgModule,
    args: [{
      declarations: [Html5VideoComponent],
      imports: [CommonModule],
      exports: [Html5VideoComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Html5VideoComponent, Html5VideoPlayerModule };
