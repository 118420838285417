import { Directive, ElementRef, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[splitting]',
})
export class SplittingDirective implements OnInit {
  @Input() by = 'chars';

  constructor(private elRef: ElementRef, @Inject(PLATFORM_ID) private platformId) {
    const target = this.elRef.nativeElement;
    if (isPlatformBrowser(this.platformId) && target) {
      // settimeout to keep up with change detection
      setTimeout(() => {
        (<any>window).Splitting({ target: target, by: this.by });
      });
    }
  }

  ngOnInit(): void {}
}
