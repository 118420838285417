import { isPlatformBrowser, NgIf, NgForOf, NgTemplateOutlet } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, PLATFORM_ID, Component, ChangeDetectionStrategy, Inject, Input, Output, ViewChild, NgModule } from '@angular/core';
import Glide from '@glidejs/glide';
const _c0 = ["glideEl"];
const _c1 = ["glideSlidesEl"];
function NgxGlideComponent_ng_container_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgxGlideComponent_ng_container_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgxGlideComponent_ng_container_16_button_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "button", 14);
  }
  if (rf & 2) {
    const glideBullet_r6 = ctx.$implicit;
    i0.ɵɵattribute("data-glide-dir", "=" + glideBullet_r6);
  }
}
function NgxGlideComponent_ng_container_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgxGlideComponent_ng_container_16_button_1_Template, 1, 1, "button", 13);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r4.glideBullets);
  }
}
const _c2 = ["*"];
const defaultExtraSettings = {
  showArrows: true,
  showBullets: true,
  arrowLeftLabel: 'left',
  arrowRightLabel: 'right',
  listenToEvents: false
};
const defaultSettings = {
  type: 'slider',
  startAt: 0,
  perView: 1,
  focusAt: 0,
  gap: 10,
  autoplay: false,
  hoverpause: true,
  keyboard: true,
  bound: false,
  swipeThreshold: 80,
  dragThreshold: 120,
  perTouch: false,
  touchRatio: 0.5,
  touchAngle: 45,
  animationDuration: 400,
  rewind: true,
  rewindDuration: 800,
  animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  direction: 'ltr',
  peek: 0,
  breakpoints: {},
  classes: {
    direction: {
      ltr: 'glide--ltr',
      rtl: 'glide--rtl'
    },
    slider: 'glide--slider',
    carousel: 'glide--carousel',
    swipeable: 'glide--swipeable',
    dragging: 'glide--dragging',
    cloneSlide: 'glide__slide--clone',
    activeNav: 'glide__bullet--active',
    activeSlide: 'glide__slide--active',
    disabledArrow: 'glide__arrow--disabled'
  },
  throttle: 25
};

// noinspection TypeScriptFieldCanBeMadeReadonly,DuplicatedCode
class NgxGlideComponent {
  constructor(changeDetectorRef, platformId) {
    this.changeDetectorRef = changeDetectorRef;
    this.platformId = platformId;
    this.showArrows = defaultExtraSettings.showArrows;
    this.showBullets = defaultExtraSettings.showBullets;
    this.arrowLeftLabel = defaultExtraSettings.arrowLeftLabel;
    this.arrowLeftTemplate = null;
    this.arrowRightLabel = defaultExtraSettings.arrowRightLabel;
    this.arrowRightTemplate = null;
    this.listenToEvents = defaultExtraSettings.listenToEvents;
    this.glideBullets = [];
    this.type = defaultSettings.type;
    this.startAt = defaultSettings.startAt;
    this.perView = defaultSettings.perView;
    this.focusAt = defaultSettings.focusAt;
    this.gap = defaultSettings.gap;
    this.autoplay = defaultSettings.autoplay;
    this.hoverpause = defaultSettings.hoverpause;
    this.keyboard = defaultSettings.keyboard;
    this.bound = defaultSettings.bound;
    this.swipeThreshold = defaultSettings.swipeThreshold;
    this.dragThreshold = defaultSettings.dragThreshold;
    this.perTouch = defaultSettings.perTouch;
    this.touchRatio = defaultSettings.touchRatio;
    this.touchAngle = defaultSettings.touchAngle;
    this.animationDuration = defaultSettings.animationDuration;
    this.rewind = defaultSettings.rewind;
    this.rewindDuration = defaultSettings.rewindDuration;
    this.animationTimingFunc = defaultSettings.animationTimingFunc;
    this.direction = defaultSettings.direction;
    this.peek = defaultSettings.peek;
    this.breakpoints = defaultSettings.breakpoints;
    this.classes = defaultSettings.classes;
    this.throttle = defaultSettings.throttle;
    this.mountedBefore = new EventEmitter();
    this.mountedAfter = new EventEmitter();
    this.updated = new EventEmitter();
    this.played = new EventEmitter();
    this.paused = new EventEmitter();
    this.buildedBefore = new EventEmitter();
    this.buildedAfter = new EventEmitter();
    this.ranBefore = new EventEmitter();
    this.ran = new EventEmitter();
    this.ranAfter = new EventEmitter();
    this.ranOffset = new EventEmitter();
    this.runStarted = new EventEmitter();
    this.runEnded = new EventEmitter();
    this.moved = new EventEmitter();
    this.movedAfter = new EventEmitter();
    this.resized = new EventEmitter();
    this.swipeStarted = new EventEmitter();
    this.swipeMoved = new EventEmitter();
    this.swipeEnded = new EventEmitter();
    this.translateJumped = new EventEmitter();
  }
  static getGlideUpdateSettings(changes) {
    const settings = {};
    for (const key in changes) {
      if (!Object.prototype.hasOwnProperty.call(changes, key)) {
        continue;
      }
      if (!Object.prototype.hasOwnProperty.call(defaultSettings, key)) {
        continue;
      }
      const change = changes[key];
      if (change.previousValue !== change.currentValue) {
        settings[key] = change.currentValue;
      }
    }
    return settings;
  }
  ngOnChanges(changes) {
    if (isPlatformBrowser(this.platformId) && changes && this.glide) {
      this.update(NgxGlideComponent.getGlideUpdateSettings(changes));
    }
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && Glide) {
      this.create();
    }
  }
  ngOnDestroy() {
    this.destroy();
  }
  getStyleDisplay(condition) {
    return condition ? 'block' : 'none';
  }
  getIndex() {
    return this.glide && this.glide.index;
  }
  // noinspection JSUnusedGlobalSymbols
  getSettings() {
    return this.glide && this.glide.settings;
  }
  // noinspection JSUnusedGlobalSymbols
  getType() {
    return this.glide && this.glide.type;
  }
  // noinspection JSUnusedGlobalSymbols
  isDisabled() {
    return this.glide && this.glide.disabled;
  }
  mount() {
    if (this.glide) {
      this.glide.mount();
    }
  }
  update(settings) {
    if (this.glide) {
      this.glide.update(settings);
      this.changeDetectorRef.detectChanges();
    }
  }
  destroy() {
    if (this.glide) {
      this.glide.destroy();
    }
  }
  // noinspection JSUnusedGlobalSymbols
  go(pattern) {
    if (this.glide) {
      this.glide.go(pattern);
    }
  }
  pause() {
    if (this.glide) {
      this.glide.pause();
    }
  }
  play(force) {
    if (this.glide) {
      if (typeof force === 'number') {
        this.glide.play(force);
      } else {
        this.glide.play();
      }
    }
  }
  // noinspection JSUnusedGlobalSymbols
  disable() {
    if (this.glide) {
      this.glide.disable();
    }
  }
  enable() {
    if (this.glide) {
      this.glide.enable();
    }
  }
  isType(type) {
    return this.glide && this.glide.isType(type);
  }
  recreate() {
    if (this.glide) {
      this.destroy();
    }
    this.create();
  }
  create() {
    this.initGlideBullets();
    this.glide = new Glide(this.glideEl?.nativeElement, this.getGlideInitSettings());
    this.initGlideEvents();
    this.mount();
    this.changeDetectorRef.detectChanges();
  }
  initGlideBullets() {
    const glideSlidesNativeElement = this.glideSlidesEl?.nativeElement;
    const childrenLength = glideSlidesNativeElement.children.length;
    this.glideBullets = [...new Array(childrenLength).keys()];
    this.changeDetectorRef.detectChanges();
  }
  initGlideEvents() {
    if (!this.glide || !this.listenToEvents) {
      return;
    }
    this.glide.on('mount.before', () => this.mountedBefore.emit());
    this.glide.on('mount.after', () => this.mountedAfter.emit());
    this.glide.on('update', () => this.updated.emit());
    this.glide.on('play', () => this.played.emit());
    this.glide.on('pause', () => this.paused.emit());
    this.glide.on('build.before', () => this.buildedBefore.emit());
    this.glide.on('build.after', () => this.buildedAfter.emit());
    this.glide.on('run.before', move => this.ranBefore.emit(move));
    this.glide.on('run', move => this.ran.emit(move));
    this.glide.on('run.after', move => this.ranAfter.emit(move));
    this.glide.on('run.offset', move => this.ranOffset.emit(move));
    this.glide.on('run.start', move => this.runStarted.emit(move));
    this.glide.on('run.end', move => this.runEnded.emit(move));
    this.glide.on('move', movement => this.moved.emit(movement));
    this.glide.on('move.after', movement => this.movedAfter.emit(movement));
    this.glide.on('resize', () => this.resized.emit());
    this.glide.on('swipe.start', () => this.swipeStarted.emit());
    this.glide.on('swipe.move', () => this.swipeMoved.emit());
    this.glide.on('swipe.end', () => this.swipeEnded.emit());
    this.glide.on('translate.jump', movement => this.translateJumped.emit(movement));
  }
  getGlideInitSettings() {
    return {
      type: this.type,
      startAt: this.startAt,
      perView: this.perView,
      focusAt: this.focusAt,
      gap: this.gap,
      autoplay: this.autoplay,
      hoverpause: this.hoverpause,
      keyboard: this.keyboard,
      bound: this.bound,
      swipeThreshold: this.swipeThreshold,
      dragThreshold: this.dragThreshold,
      perTouch: this.perTouch,
      touchRatio: this.touchRatio,
      touchAngle: this.touchAngle,
      animationDuration: this.animationDuration,
      rewind: this.rewind,
      rewindDuration: this.rewindDuration,
      animationTimingFunc: this.animationTimingFunc,
      direction: this.direction,
      peek: this.peek,
      breakpoints: this.breakpoints,
      classes: this.classes,
      throttle: this.throttle
    };
  }
  static {
    this.ɵfac = function NgxGlideComponent_Factory(t) {
      return new (t || NgxGlideComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PLATFORM_ID));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxGlideComponent,
      selectors: [["ngx-glide"]],
      viewQuery: function NgxGlideComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.glideEl = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.glideSlidesEl = _t.first);
        }
      },
      inputs: {
        showArrows: "showArrows",
        showBullets: "showBullets",
        arrowLeftLabel: "arrowLeftLabel",
        arrowLeftTemplate: "arrowLeftTemplate",
        arrowRightLabel: "arrowRightLabel",
        arrowRightTemplate: "arrowRightTemplate",
        listenToEvents: "listenToEvents",
        type: "type",
        startAt: "startAt",
        perView: "perView",
        focusAt: "focusAt",
        gap: "gap",
        autoplay: "autoplay",
        hoverpause: "hoverpause",
        keyboard: "keyboard",
        bound: "bound",
        swipeThreshold: "swipeThreshold",
        dragThreshold: "dragThreshold",
        perTouch: "perTouch",
        touchRatio: "touchRatio",
        touchAngle: "touchAngle",
        animationDuration: "animationDuration",
        rewind: "rewind",
        rewindDuration: "rewindDuration",
        animationTimingFunc: "animationTimingFunc",
        direction: "direction",
        peek: "peek",
        breakpoints: "breakpoints",
        classes: "classes",
        throttle: "throttle"
      },
      outputs: {
        mountedBefore: "mountedBefore",
        mountedAfter: "mountedAfter",
        updated: "updated",
        played: "played",
        paused: "paused",
        buildedBefore: "buildedBefore",
        buildedAfter: "buildedAfter",
        ranBefore: "ranBefore",
        ran: "ran",
        ranAfter: "ranAfter",
        ranOffset: "ranOffset",
        runStarted: "runStarted",
        runEnded: "runEnded",
        moved: "moved",
        movedAfter: "movedAfter",
        resized: "resized",
        swipeStarted: "swipeStarted",
        swipeMoved: "swipeMoved",
        swipeEnded: "swipeEnded",
        translateJumped: "translateJumped"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 17,
      vars: 17,
      consts: [[1, "glide"], ["glideEl", ""], ["data-glide-el", "track", 1, "glide__track"], [1, "glide__slides"], ["glideSlidesEl", ""], ["data-glide-el", "controls", 1, "glide__arrows"], ["data-glide-dir", "<", 1, "glide__arrow", "glide__arrow--left"], ["data-glide-dir", ">", 1, "glide__arrow", "glide__arrow--right"], ["data-glide-dir", "<"], [4, "ngTemplateOutlet"], ["data-glide-dir", ">"], ["data-glide-el", "controls[nav]", 1, "glide__bullets"], [4, "ngIf"], ["class", "glide__bullet", 4, "ngFor", "ngForOf"], [1, "glide__bullet"]],
      template: function NgxGlideComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0, 1)(2, "div", 2)(3, "div", 3, 4);
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(6, "div", 5)(7, "button", 6);
          i0.ɵɵtext(8);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "button", 7);
          i0.ɵɵtext(10);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(11, "div", 8);
          i0.ɵɵtemplate(12, NgxGlideComponent_ng_container_12_Template, 1, 0, "ng-container", 9);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(13, "div", 10);
          i0.ɵɵtemplate(14, NgxGlideComponent_ng_container_14_Template, 1, 0, "ng-container", 9);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(15, "div", 11);
          i0.ɵɵtemplate(16, NgxGlideComponent_ng_container_16_Template, 2, 1, "ng-container", 12);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(6);
          i0.ɵɵstyleProp("display", ctx.showArrows ? "block" : "none");
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("display", ctx.getStyleDisplay(!ctx.arrowLeftTemplate));
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.arrowLeftLabel, " ");
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("display", ctx.getStyleDisplay(!ctx.arrowRightTemplate));
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.arrowRightLabel, " ");
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("display", ctx.getStyleDisplay(!!ctx.arrowLeftTemplate));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngTemplateOutlet", ctx.arrowLeftTemplate);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("display", ctx.getStyleDisplay(!!ctx.arrowRightTemplate));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngTemplateOutlet", ctx.arrowRightTemplate);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("display", ctx.showBullets ? "block" : "none");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.glideBullets);
        }
      },
      dependencies: [NgIf, NgForOf, NgTemplateOutlet],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGlideComponent, [{
    type: Component,
    args: [{
      standalone: true,
      imports: [NgIf, NgForOf, NgTemplateOutlet],
      selector: 'ngx-glide',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div #glideEl class=\"glide\">\n  <div class=\"glide__track\" data-glide-el=\"track\">\n    <div #glideSlidesEl class=\"glide__slides\">\n      <ng-content></ng-content>\n    </div>\n  </div>\n  <div\n    class=\"glide__arrows\"\n    data-glide-el=\"controls\"\n    [style.display]=\"showArrows ? 'block' : 'none'\"\n  >\n    <button\n      class=\"glide__arrow glide__arrow--left\"\n      data-glide-dir=\"<\"\n      [style.display]=\"getStyleDisplay(!arrowLeftTemplate)\"\n    >\n      {{ arrowLeftLabel }}\n    </button>\n    <button\n      class=\"glide__arrow glide__arrow--right\"\n      data-glide-dir=\">\"\n      [style.display]=\"getStyleDisplay(!arrowRightTemplate)\"\n    >\n      {{ arrowRightLabel }}\n    </button>\n    <div\n      data-glide-dir=\"<\"\n      [style.display]=\"getStyleDisplay(!!arrowLeftTemplate)\"\n    >\n      <ng-container *ngTemplateOutlet=\"arrowLeftTemplate\"></ng-container>\n    </div>\n    <div\n      data-glide-dir=\">\"\n      [style.display]=\"getStyleDisplay(!!arrowRightTemplate)\"\n    >\n      <ng-container *ngTemplateOutlet=\"arrowRightTemplate\"></ng-container>\n    </div>\n  </div>\n  <div\n    class=\"glide__bullets\"\n    data-glide-el=\"controls[nav]\"\n    [style.display]=\"showBullets ? 'block' : 'none'\"\n  >\n    <ng-container *ngIf=\"glideBullets\">\n      <button\n        *ngFor=\"let glideBullet of glideBullets\"\n        class=\"glide__bullet\"\n        [attr.data-glide-dir]=\"'=' + glideBullet\"\n      ></button>\n    </ng-container>\n  </div>\n</div>\n"
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }];
  }, {
    showArrows: [{
      type: Input
    }],
    showBullets: [{
      type: Input
    }],
    arrowLeftLabel: [{
      type: Input
    }],
    arrowLeftTemplate: [{
      type: Input
    }],
    arrowRightLabel: [{
      type: Input
    }],
    arrowRightTemplate: [{
      type: Input
    }],
    listenToEvents: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    startAt: [{
      type: Input
    }],
    perView: [{
      type: Input
    }],
    focusAt: [{
      type: Input
    }],
    gap: [{
      type: Input
    }],
    autoplay: [{
      type: Input
    }],
    hoverpause: [{
      type: Input
    }],
    keyboard: [{
      type: Input
    }],
    bound: [{
      type: Input
    }],
    swipeThreshold: [{
      type: Input
    }],
    dragThreshold: [{
      type: Input
    }],
    perTouch: [{
      type: Input
    }],
    touchRatio: [{
      type: Input
    }],
    touchAngle: [{
      type: Input
    }],
    animationDuration: [{
      type: Input
    }],
    rewind: [{
      type: Input
    }],
    rewindDuration: [{
      type: Input
    }],
    animationTimingFunc: [{
      type: Input
    }],
    direction: [{
      type: Input
    }],
    peek: [{
      type: Input
    }],
    breakpoints: [{
      type: Input
    }],
    classes: [{
      type: Input
    }],
    throttle: [{
      type: Input
    }],
    mountedBefore: [{
      type: Output
    }],
    mountedAfter: [{
      type: Output
    }],
    updated: [{
      type: Output
    }],
    played: [{
      type: Output
    }],
    paused: [{
      type: Output
    }],
    buildedBefore: [{
      type: Output
    }],
    buildedAfter: [{
      type: Output
    }],
    ranBefore: [{
      type: Output
    }],
    ran: [{
      type: Output
    }],
    ranAfter: [{
      type: Output
    }],
    ranOffset: [{
      type: Output
    }],
    runStarted: [{
      type: Output
    }],
    runEnded: [{
      type: Output
    }],
    moved: [{
      type: Output
    }],
    movedAfter: [{
      type: Output
    }],
    resized: [{
      type: Output
    }],
    swipeStarted: [{
      type: Output
    }],
    swipeMoved: [{
      type: Output
    }],
    swipeEnded: [{
      type: Output
    }],
    translateJumped: [{
      type: Output
    }],
    glideEl: [{
      type: ViewChild,
      args: ['glideEl', {
        static: false
      }]
    }],
    glideSlidesEl: [{
      type: ViewChild,
      args: ['glideSlidesEl', {
        static: false
      }]
    }]
  });
})();
class NgxGlideModule {
  static {
    this.ɵfac = function NgxGlideModule_Factory(t) {
      return new (t || NgxGlideModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxGlideModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGlideModule, [{
    type: NgModule,
    args: [{
      imports: [NgxGlideComponent],
      exports: [NgxGlideComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxGlideComponent, NgxGlideModule };
