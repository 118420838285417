import * as i0 from '@angular/core';
import { InjectionToken, Component, ChangeDetectionStrategy, Inject, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * This is not a real service, but it looks like it from the outside.
 * It's just an InjectionTToken used to import the config object, provided from the outside
 */
const _c0 = a0 => [a0];
const IconConfigService = new InjectionToken('IconConfig');
class IconComponent {
  /**
   *
   */
  constructor(config) {
    this.config = config;
    this.viewbox = '';
    this.baseUrl = '/assets/';
    if (!config?.spritesheetUrl) {
      console.error('No spritesheet URL defined');
    }
    this.baseUrl = config.spritesheetUrl;
  }
  ngOnInit() {
    // warn user when icon is not properly set
    if (!this.anchor) {
      console.warn('icon anchor is required');
    }
  }
  static {
    this.ɵfac = function IconComponent_Factory(t) {
      return new (t || IconComponent)(i0.ɵɵdirectiveInject(IconConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: IconComponent,
      selectors: [["fstr-icon"]],
      inputs: {
        anchor: "anchor",
        title: "title",
        viewbox: "viewbox"
      },
      decls: 4,
      vars: 6,
      consts: [["aria-hidden", "true", 1, "icon", 3, "ngClass"]],
      template: function IconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "svg", 0)(1, "title");
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(3, "use");
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c0, "is--" + ctx.anchor));
          i0.ɵɵattribute("viewbox", ctx.viewbox);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.title || ctx.anchor);
          i0.ɵɵadvance();
          i0.ɵɵattribute("href", ctx.baseUrl + "#" + ctx.anchor, null, "xlink");
        }
      },
      dependencies: [i1.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconComponent, [{
    type: Component,
    args: [{
      // tslint:disable-next-line: component-selector
      selector: 'fstr-icon',
      template: `
    <svg
      class="icon"
      [attr.viewbox]="viewbox"
      [ngClass]="['is--' + anchor]"
      aria-hidden="true"
    >
      <title>{{ title || anchor }}</title>
      <use [attr.xlink:href]="baseUrl + '#' + anchor"></use>
    </svg>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [IconConfigService]
      }]
    }];
  }, {
    anchor: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    viewbox: [{
      type: Input
    }]
  });
})();
class IconModule {
  static forRoot(config) {
    const cfg = {
      spritesheetUrl: '/assets/dist/svg/symbol/svg/sprite.symbol.svg',
      ...config
    };
    return {
      ngModule: IconModule,
      providers: [{
        provide: IconConfigService,
        useValue: cfg
      }]
    };
  }
  static {
    this.ɵfac = function IconModule_Factory(t) {
      return new (t || IconModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: IconModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconModule, [{
    type: NgModule,
    args: [{
      declarations: [IconComponent],
      exports: [IconComponent],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IconComponent, IconConfigService, IconModule };
